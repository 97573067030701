.dashboard-container {
    width: 100%;
    height: 100%;
    background-color: grey;
    padding: 2rem;
}

.dashboard {
    background-color: white;
    border: 2px solid black;
    list-style: none;
    padding: 1rem;
}

.alert-component {
    border: 1px solid black;
    margin-bottom: 1rem;
    background-color: beige;
    cursor: pointer;
    padding: 0.2rem;
}